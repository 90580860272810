import cn from "classnames"
import React from "react"

interface PropTypes {
  text: string
  variant?: "primary" | "secondary"
}

const Badge = ({ text, variant = "primary" }: PropTypes) => {
  return (
    <span
      className={cn(
        "dark:bg-gray-900 inline-block rounded px-2 py-0.5 text-xs font-medium",
        variant == "primary" && "bg-cyan-50 text-cyan-500",
        variant == "secondary" &&
          "dark:text-gray-300 bg-gray-100 text-gray-600",
      )}
    >
      {text}
    </span>
  )
}

export default Badge
