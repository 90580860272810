import cn from "classnames"
import React, { ComponentProps } from "react"
import Image from "../../../resolvers/image"
import Badge from "../../freestanding/badge"
import Container from "../../freestanding/container"
import Section from "../../freestanding/section"
import Wrapper from "../../freestanding/wrapper"

export interface ArticleHeroProps {
  title: string
  image?: ComponentProps<typeof Image>
  author: {
    image: ComponentProps<typeof Image>
    name: string
    role: string
  }
  categories: string[]
  publishedAt: string
  readingTime: number
}

const ArticleHero = ({
  title,
  image,
  author,
  categories,
  publishedAt,
  readingTime,
}: ArticleHeroProps) => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <div className="col-span-full grid lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3">
            <div className="col-span-full grid gap-4">
              <h1 className="dark:text-cyan-50 pb-6 text-4xl text-indigo-900 md:text-5xl xl:text-6xl">
                {title}
              </h1>
              <div className="col-span-full flex items-center space-x-2">
                <Image
                  {...author.image}
                  className={cn(
                    "aspect-square w-12 rounded-full",
                    author.image.className,
                  )}
                  objectPosition="top"
                />
                <div className="grid">
                  <div className="dark:text-cyan-50 col-span-full text-sm font-medium text-indigo-900">
                    {author.name}
                  </div>
                  <div className="dark:text-gray-300 col-span-full text-sm text-gray-600">
                    {author.role}
                  </div>
                </div>
              </div>
              <div className="flex space-x-1.5">
                {categories.map((category, idx) => (
                  <Badge text={category} key={idx} />
                ))}
                <Badge text={publishedAt} variant="secondary" />
                <Badge text={readingTime + " min read"} variant="secondary" />
              </div>
            </div>
          </div>
          {image && (
            <div className="col-span-full">
              <Image {...image} className="aspect-[120/63] w-full" />
            </div>
          )}
        </Wrapper>
      </Container>
    </Section>
  )
}

export default ArticleHero
