import classNames from "classnames"
import { PropsWithChildren } from "react"

interface PropTypes {
  className?: string
  noVerticalPadding?: boolean
}

// x-padding is defined globally at tailwind.config.js for the container class
// TODO: standardize the x and y padding for all sections at the figma file
export const Section = ({
  children,
  className,
  noVerticalPadding = false,
}: PropsWithChildren<PropTypes>) => {
  return (
    <section
      className={classNames(
        "container relative mx-auto flex flex-col",
        { "py-6 md:py-24": !noVerticalPadding },
        className,
      )}
    >
      {children}
    </section>
  )
}

export default Section
