import loadable from "@loadable/component"
import { graphql, PageProps } from "gatsby"
import React from "react"
import Layout from "../components/layouts/layout/layout"
import Section from "../components/freestanding/section"
import ArticleHero from "../components/layouts/blog/article-hero"

const Markdown = loadable(
  () => import("../components/layouts/markdown/markdown"),
)

const BlogPostTemplate = ({ data }: PageProps<Queries.BlogPostQuery>) => {
  const frontmatter = data?.mdx?.frontmatter
  const author = data.author?.frontmatter
  if (!frontmatter || !author) return null

  return (
    <Layout>
      {/* @ts-ignore */}
      <ArticleHero
        {...frontmatter}
        image={{
          src: frontmatter.photo!.image!.childImageSharp!.gatsbyImageData,
          alt: frontmatter.photo!.alt!,
        }}
        author={{
          name: author.name!,
          role: author.role!,
          image: {
            src: author.photo!.image!.childImageSharp!.gatsbyImageData,
            alt: author.photo!.alt!,
          },
        }}
        // TODO: add library to compute reading time more accurately
        readingTime={Math.round(frontmatter.content!.split(" ").length / 200)}
      />
      <Section>
        <Markdown>{frontmatter.content!}</Markdown>
      </Section>
    </Layout>
  )
}

export default BlogPostTemplate

export const Head = ({ data }: PageProps<Queries.BlogPostQuery>) => {
  const seo = data.mdx?.frontmatter?.seo
  if (!seo) return null

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description!} />
      <meta property="og:title" content={seo.title!} />
      <meta property="og:title" content={seo.description!} />
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="ORY" />
      <meta property="twitter:title" content={seo.title!} />
      <meta property="twitter:description" content={seo.description!} />
    </>
  )
}

export const blogPostTemplateQuery = graphql`
  query BlogPost($slug: String!, $authorName: String!) {
    mdx(slug: { eq: $slug }) {
      slug
      frontmatter {
        slug
        author
        title
        categories
        photo {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
        }
        tags
        content
        publishedAt(formatString: "MMMM DD, YYYY")
        seo {
          title
          description
        }
      }
    }
    author: mdx(
      frontmatter: { type: { eq: "person" }, name: { eq: $authorName } }
    ) {
      frontmatter {
        name
        role
        photo {
          alt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
